<template>
  <div>
    <div
      class="modal fade"
      id="ShopifySearchProductModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      v-bind="$attrs"
      ref="upgradePlanModal"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content" id="FilesModalContent">
          <!-- Modal Content -->
          <div class="container px-4">
            <div
              class="row px-3 py-3 d-flex justify-content-end align-items-center"
            >
              <div>
                <button
                  @click="CloseModal"
                  class="btn outline-none outline-none border-0"
                  style="cusrsor: pointer; color: #323232"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div class="row pb-5">
              <div class="pb-5 col-12">
                <div class="card-header d-flex justify-content-between">
                  <h3>Manage Products</h3>
                  <router-link
                    to="/user/product/create"
                    class="btn"
                    style="
                      background: #4d1b7e;
                      border-radius: 6px;
                      color: #ffffff;
                    "
                  >
                    Create New
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </router-link>
                </div>
                <div class="card-body" v-if="Products">
                  <!-- Table Row start-->
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <b-form-input
                      class="w-50 mb-3"
                      placeholder="Search Products"
                      v-model="searchInput"
                      @input="loadProducts"
                    ></b-form-input>
                    <!-- Filter & delteButtons -->

                    <!-- Filter & delteButtons -->
                  </div>
                  <!-- Table Row end-->
                  <!-- Table start -->
                  <div class="row">
                    <div class="col-12 ProductDataTable">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Image</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(row, index) in Products"
                            :key="'ind' + index"
                          >
                            <td scope="row">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                              />
                            </td>
                            <td><img src="" alt="" /></td>
                            <td>{{ row.title }}</td>
                            <td>Otto</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- Table end -->
                </div>
                <!-- If PRoduct is Empty -->
                <div v-else>
                  <h3 class="text-center">No Product Found!</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from 'jquery'
import axios from "axios";
export default {
  data() {
    return {
      searchInput: "",
      value: null,
      Products: [],
      options: ["list", "of", "options"],
      isAllProductLoading: false,
      deleteProductLoading: false,
      productVendorFilter: null,
      TaggedWithFilter: null,
      StatusFilter: null,
      searchProducts: "",
      selectedProducts: [],
    };
  },
  computed: {
    ...mapGetters(["GetTokenFromMetaTag", "getQuizID"]),
    getSingleSelectedProduct() {
      if (this.selectedProducts.length) return this.selectedProducts[0];
      return null;
    },
    getProductsTable() {
      return this.Products;
      //         return this.Products.filter((item) => {
      //     return (this.searchProducts.length === 0 || item.title.toLowerCase().includes(this.searchProducts.toLowerCase()))
      //     &&
      //     ( (this.productVendorFilter == null  ) || this.productVendorFilter.toLowerCase().includes(item.vendor.toLowerCase()))
      //     &&
      //     (this.StatusFilter == null || this.StatusFilter == item.isActive)
      //      &&

      //     (this.TaggedWithFilter == null || item.tags.some(x=> x.toLowerCase() == this.TaggedWithFilter.toLowerCase()))
      //   })
    },

    getProductsToDelete() {
      if (this.selectedProducts.length) {
        let deleteProductId = [];

        for (let i = 0; i < this.selectedProducts.length; i++) {
          deleteProductId.push(this.selectedProducts[i].id);
        }

        return deleteProductId;
      } else {
        return null
      }
    },
  },
  methods: {
    loadProducts() {
      axios
        .post("/searchShopifyProduct", { value: this.searchInput })
        .then((resp) => {
          this.Products = resp.data;
        });
    },
    OpenModal() {
      // this.loadShopifyProductModal();
      $("#ShopifySearchProductModal").modal("show");
    },
    CloseModal() {
      $("#ShopifySearchProductModal").modal("hide");
    },
    getFirstImage(images) {
      if (images.length) {
        return this.currentProduct.productImages[0];
      }
      return "/images/image_placeholder.png";
    },
    ReturnSelectedProduct(product) {
      //   this.$rooter.push('')
      this.$router.push({
        name: "EditProduct",
        params: { productId: product.id },
      });
      this.$emit("getSelectedProduct", product);
    },
    OnRowCheckBoxChanged(e) {
      this.selectedProducts = e.selected_items;
    },
    ToggleProductStatus() {
      const id = this.getSingleSelectedProduct.id;
      console.log("ID", id);

      const index = this.Products.findIndex((x) => x.id == id);

      if (index > -1) {
        this.Products[index].isActive = !this.Products[index].isActive;
      }
    },
    AddNewProduct() {
      this.$emit("AddNewProductView");
    },
    ProductMatch() {
      this.$emit("ProductMatch");
    },
    ResetFilters() {
      this.productVendorFilter = null;
      this.TaggedWithFilter = null;
      this.StatusFilter = null;
      this.searchProducts = "";
    },
  },
  mounted() {
    //   this.loadInitialProducts()
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.headTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #4b494e;
}
.ProductTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #18191c;
}
.ProductPrice {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #18191c;
}
.ProductVendor {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #18191c;
}

.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}
.duplicateProduct {
  background: rgba(77, 27, 126, 0.2);
  border-radius: 6px;
  color: #4d1b7e;
}
.disableProduct {
  background: rgba(179, 175, 182, 0.2);
  border-radius: 6px;
  color: #4d4950;
}
.enableProduct {
  background: #64bc2633;
  border-radius: 6px;
  color: #64bc26;
}
/* Product Data Table Styles  */
.ProductDataTable .DataTable.card {
  border: none;
}
.ProductDataTable >>> .card-header {
  display: none !important;
}
.ProductDataTable >>> .card-footer {
  background: transparent;
  padding: 0 !important;
  border: none;
}

.ProductDataTable >>> .vbt-per-page-dropdown .btn-primary {
  background: rgba(77, 27, 126, 0.8) !important;
  border: none !important;
  color: #ffffff !important;
}
.ProductDataTable >>> .vbt-per-page-dropdown .dropdown-item.active {
  background: rgba(77, 27, 126, 0.8) !important;
  text-decoration: none !important;
  color: #ffffff !important;
}

/* Select filter Group styles */
.FilterDiv {
  width: fit-content;
  /* border: 1px solid red; */
  height: 30px;
  margin-top: 20px;
}
.SelectFormGroup {
  margin: 0 !important;
}
/* .SelectFormGroup .custom-select{
    border:0 !important;
} */
</style>
